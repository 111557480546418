var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('base-material-company-wizard',{staticClass:"mx-auto",attrs:{"available-steps":_vm.availableSteps,"items":_vm.tabs},on:{"click:next":function($event){return _vm.next(valid)},"click:prev":function($event){_vm.tab--},"click:abort":function($event){return _vm.$emit('wizardcanceled')}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"pb-12"},[_c('form',[_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('image-upload',{attrs:{"image":_vm.image,"image-content-type":_vm.imageContentType},on:{"imageLoaded":_vm.setImage}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":_vm.$t('name') + '*',"prepend-icon":"mdi-account","validate-on-blur":""},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"email","name":_vm.$t('email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":_vm.$t('email') + '*',"prepend-icon":"mdi-email","validate-on-blur":""},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}})]}}],null,true)})],1)],1)],1)]),_c('v-tab-item',{staticClass:"pb-12"},[_c('div',{staticClass:"text-center display-1 grey--text font-weight-light mb-6"},[_vm._v(" "+_vm._s(_vm.$t('dialog.where-is-the-company-located'))+" ")]),_c('form',[_c('v-row',{staticClass:"mx-auto",staticStyle:{"max-width":"500px"},attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"color":"secondary","label":_vm.$t('street'),"validate-on-blur":""},model:{value:(_vm.company.metadata.address.street),callback:function ($$v) {_vm.$set(_vm.company.metadata.address, "street", $$v)},expression:"company.metadata.address.street"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"color":"secondary","label":_vm.$t('zipcode'),"validate-on-blur":""},model:{value:(_vm.company.metadata.address.zip),callback:function ($$v) {_vm.$set(_vm.company.metadata.address, "zip", $$v)},expression:"company.metadata.address.zip"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"color":"secondary","label":_vm.$t('city'),"validate-on-blur":""},model:{value:(_vm.company.metadata.address.city),callback:function ($$v) {_vm.$set(_vm.company.metadata.address, "city", $$v)},expression:"company.metadata.address.city"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"state"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"autocomplete":Date.now(),"error-messages":errors,"items":_vm.countries,"color":"secondary","label":_vm.$t('country') + '*',"validate-on-blur":""},model:{value:(_vm.company.metadata.address.country),callback:function ($$v) {_vm.$set(_vm.company.metadata.address, "country", $$v)},expression:"company.metadata.address.country"}})]}}],null,true)})],1)],1)],1)]),(_vm.createAsAdmin)?_c('v-tab-item',{staticClass:"pb-12"},[_c('div',{staticClass:"text-center display-1 grey--text font-weight-light mb-6"},[_vm._v(" "+_vm._s(_vm.$t('dialog.who-works-in-the-company'))+" ")]),_c('v-col',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"cols":"12","md":"12"}},[_c('v-autocomplete',{staticStyle:{"margin-right":"30px"},attrs:{"search-input":_vm.userAutocomplete,"autocomplete":Date.now(),"error-messages":_vm.autocompleteError,"items":_vm.users,"color":"secondary","label":_vm.$t('coworker'),"validate-on-blur":"","item-text":"username","item-value":"_id"},on:{"update:searchInput":function($event){_vm.userAutocomplete=$event},"update:search-input":function($event){_vm.userAutocomplete=$event}},model:{value:(_vm.selectedCoworker),callback:function ($$v) {_vm.selectedCoworker=$$v},expression:"selectedCoworker"}}),_c('v-btn',{attrs:{"color":"success","disabled":!_vm.selectedCoworker.length},on:{"click":_vm.addUserToCompany}},[_vm._v(" "+_vm._s(_vm.$t('add'))+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.selectedCoworkers),function(user){return _c('div',{key:user._id},[_vm._v(" "+_vm._s(user.username)+" - "+_vm._s(user.email)+", ")])}),0)],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }