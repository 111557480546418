

























































































































































































import { COUNTRIES } from '@/shared/constants'
import { debounceInput } from '@/shared/helpers'
import { Company, CompanyRole, IUserSubsegment } from '@/shared/model/company.model'
import { IUser, User } from '@/shared/model/user.model'
import companyService from '@/shared/services/companyService'
import userService from '@/shared/services/userService'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  components: {
    ImageUpload: () => import('@/views/app/_components/ImageUpload.vue'),
  },
  props: {
    createAsAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      company: new Company(),
      image: null as null | string,
      imageContentType: null as any | string,
      countries: COUNTRIES,
      tab: 0,
      // tabs: ['Kontakt', 'Details'],
      tabs: [this.$t('contact').toString(), this.$t('details').toString()],

      userAutocomplete: '',
      debounce: undefined as number | undefined,
      selectedCoworker: '' as string,
      selectedCoworkers: [] as IUser[],
      autocompleteError: '' as string,
    }
  },
  computed: {
    ...mapGetters({
      users: 'userStore/getList',
      totalUsers: 'userStore/getTotal',
    }),
    availableSteps () {
      const steps = [0]

      if (this.company.name && this.company.email && this.company.metadata) {
        steps.push(1)
      }

      if (
        this.company.metadata &&
        this.company.metadata.address &&
        this.company.metadata.address.country &&
        steps.includes(1)
      ) {
        steps.push(2)
      }

      if (this.createAsAdmin && steps.includes(2)) {
        steps.push(3)
      }

      return steps
    },
  },
  watch: {
    userAutocomplete: {
      async handler () {
        this.autocompleteError = ''
        this.debounce = debounceInput(this.debounce, this.getAllUsersForAutocomplete)
      },
    },
  },
  created () {
    if (this.createAsAdmin) {
      this.tabs.push(this.$t('coworker').toString())
    }
  },
  methods: {
    addUserToCompany () {
      if (this.selectedCoworker) {
        companyService.getUsersCompany(this.selectedCoworker).then(result => {
          if (result.data && result.data.length) {
            this.autocompleteError = 'Dieser Benutzer ist bereits in einer Firma'
          } else {
            const user = this.users.find((x: IUser) => x._id === this.selectedCoworker)
            if (user) {
              if (!this.selectedCoworkers.find((x: IUser) => x._id === user._id)) {
                this.selectedCoworkers.push(user)
                this.selectedCoworker = ''
              } else {
                this.autocompleteError = 'Dieser Benutzer wurde bereits ausgewählt'
              }
            }
          }
        })
      }
    },
    async next (valid: any) {
      if (this.tab === this.tabs.length - 1) {
        if (!this.createAsAdmin) {
          await companyService
            .createCompany(this.company)
            .then(result => {
              this.$emit('wizardfinished')
              this.$toast(this.$t('dialog.company-created-successfully').toString())
            })
            .catch(e => {
              console.error('createCompany() failed', e)
              if (e.response.status === 400) {
                this.$toast.error(this.$t('dialog.company-already-exists').toString())
              } else {
                this.$toast.error(this.$t('dialog.create-company-failed').toString())
              }
            })
        } else {
          this.selectedCoworkers.forEach((user: IUser) => {
            const coworker: IUserSubsegment = {
              user: user,
              companyRoles: [CompanyRole.employee],
            }
            if (!this.company.users) {
              this.company.users = []
            }
            this.company.users.push(coworker)
          })
          await companyService
            .createCompanyAsAdmin(this.company)
            .then(result => {
              this.$emit('wizardfinished')
              this.$toast(this.$t('dialog.company-created-successfully').toString())
            })
            .catch(e => {
              console.error('createCompany() failed', e)
              if (e.response.status === 400) {
                this.$toast.error(this.$t('dialog.company-already-exists').toString())
              } else {
                this.$toast.error(this.$t('dialog.create-company-failed').toString())
              }
            })
        }
      } else {
        this.tab++
      }
    },
    async getAllUsersForAutocomplete () {
      const query = {
        l: 10,
        lo: 0,
        sf: 'username',
        so: 'desc',
        search: { search: this.userAutocomplete },
      }
      await userService.getAllUsersPaginated(query)
    },
    setImage: function (blobContent: any) {
      if (blobContent && blobContent.image) {
        this.image = blobContent.image
        if (blobContent.imageContentType) {
          this.imageContentType = blobContent.imageContentType
        } else {
          this.imageContentType = null
        }
        this.image = blobContent.image
        if (this.company.metadata) {
          this.company.metadata.logo = blobContent.image
        }
      }
    },
  },
})
